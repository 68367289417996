<template>
	<!-- 公开公示 -->
	<div class="notice-container">
		<div class="banner-size">
			<div class="banner-limit banner-info flex-row-between">
				<!-- 部门名称的 -->
				<div class="dept-name">
					{{dept.deptName}}
				</div>
				<!-- 申报 -->
				<div class="flex-row">
					<div class="dept-detail flex-center">
						<span>{{dept.delFlag}}</span>
						<p>申报</p>
					</div>
					<div class="one-line"></div>
					<div class="dept-detail">
						<span>{{dept.orderNum}}</span>
						<p>已扶持</p>
					</div>
				</div>

			</div>
		</div>

		<div class="deptinfo-body">
			<div>
				<DeptHeader title="项目申报"></DeptHeader>
				<el-tabs v-model="activeName" class="width-limit" @tab-click="handleClick">
				  <el-tab-pane label="行政审批类" name="first">
				  </el-tab-pane>
				  <el-tab-pane label="资金扶持类" name="second">
				  </el-tab-pane>
				  <el-tab-pane label="企业述求类" name="third">
				  </el-tab-pane>
				</el-tabs>
				<!--内容部分-->
				<div v-if="showList != null" ref="findList" class="items-container">
				  <div class="item" v-for="(item, i) in showList" :key="i">
				    <DeclareCell
						:key="item.id"
						:collectItem="isItemCollect(item)"
						:item="item"
						@clickCell="clickCell"
						@clickIsFollow="clickIsFollow"
				    ></DeclareCell>
				  </div>
				</div>
				<el-image v-else class="img" :src="src"></el-image>
			</div>
			<div>
				<DeptHeader title="申报通知" name="first"></DeptHeader>
				<DeptForm class=" " :lists="tongzhiList" :itemType="2"></DeptForm>
				<el-pagination style="margin-top: 20px" layout="total, prev, pager, next" background :total="tongzhiTotalSize"
				 :page-size="pageSize" @current-change="changetongzhi"></el-pagination>
			</div>
			<div>
				<DeptHeader title="支持政策"></DeptHeader>
				<DeptForm class=" " :lists="dataList" :itemType="1"></DeptForm>
				<el-pagination style="margin-top: 20px" layout="total, prev, pager, next" background :total="totalSize"
				 :page-size="pageSize" @current-change="changezhengce"></el-pagination>
			</div>
			<div>
				<DeptHeader title="项目公示"></DeptHeader>
				<DeptForm class=" " :lists="tableData" :itemType="3"></DeptForm>
				<el-pagination style="margin-top: 20px" layout="total, prev, pager, next" background :total="gongkaiTotalSize"
				 :page-size="pageSize" @current-change="changeshixiang"></el-pagination>
			</div>
		</div>
		<!--登录提示弹窗-->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
		  <span>需要登录才能收藏，是否登录？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="gotoLogin">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import DeptHeader from '@/components/deptHeader'
	import DeptForm from '@/components/deptForm'
	import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片
	import {
		Loading
	} from "element-ui";
	export default {
		components: {
			DeptForm,
			DeptHeader,
			DeclareCell,
		},
		computed: {
			...mapGetters({
				// 把 `this.userInfo` 映射为 `this.$store.getters.getUserInfo`
				userInfo: "getUserInfo",
			}),
		},
		data() {
			return {
				// 弹窗
				dialogVisible:false,
				dept: {

					deptName: '测试部门',
					shenbaonum: 2, //申报数量
					hasPolicy: 111, //已经扶持
				},
				pageSize:5,
				activeName:"first",
				activeTab:"0",
				deptid: "",
				src: require('@/assets/404_images/no_data.png'),
				// 公开公示列表 
				tableData: [],
				lixiangNum: 0,
				gongkaiTotalSize: 0,
				xiangmuPageNum:1,
				// 政策
				dataList: [],
				totalSize: 0,
				zhengcePageNum:1,//政策的当前页
				// 通知
				tongzhiList:[],
				tongzhiTotalSize:0,
				tongzhiPageNum:1,//通知的当前页
				// 项目申报的数组
				showList:[],
				sortcode: "ZZRD0001", // 事项大类id、
				targetArray:[],//处理数组
				shenbaoTotal:0,
				pagerSize: 3, // 3行 4列
				collectList:[],
			};
		},
		mounted() {
			
			const that = this;
			const id = that.$route.params.id; // 接收的参数ID
			that.deptid = id;
			this.getCollectList();
			this.getTongzhiList();
			this.getPublicList();
			this.getZhengceList();
			this.getDeptInfo();
			this.getSPFCList();
			
		},
		methods: {
			//查询所有的收藏列表,之后需要比对事项的收藏状态
			getCollectList() {
			  if (this.$store.getters.getUserInfo) {
			    let identify = this.$store.getters.getUserInfo.shxydm;
			    let param = {
			      identify: identify,
			      type: "1,2,3,4,5,6",
			      pageNum: 1,
			      pageSize: 100,
			    };
			    let url = "/dev-api/collection/list";
			
			    this.$httpApi
			      .get(url, param)
			      .then((res) => {
			        if (res.code == 200) {
			          this.collectList = res.rows;
			        }
			      })
			      .catch((err) => {});
			  }
			},
			/**
			 * 监听declareCeil子组件点击收藏事件
			 */
			clickIsFollow(item) {
			  //判断是否登录
			  if (this.$store.getters.getUserInfo) {
			    //登录
			  } else {
			    //未登录
			    this.dialogVisible = true;
			  }
			},
			/**
			 * 登录提示框点击确定登录，跳转登录页面
			 */
			gotoLogin() {
			  console.log("跳转登录页面");
			  this.$router.push({
			    path: "/login",
			  });
			},
			//比对收藏列表,判断是否收藏
			isItemCollect(item) {
			  let list = this.collectList;
			
			  for (let i = 0; i < list.length; i++) {
			    let collectItem = list[i];
			    if (item.id == collectItem.collectionId) {
			      console.log(item.name + ":已收藏");
			      return collectItem;
			    }
			  }
			  return null;
			},
			// 改变通知政策事项的当前页所触发的函数
			changetongzhi(current) {
				//current是当前页数
				this.tongzhiPageNum = current
				this.getTongzhiList()
			},
			changezhengce(current) {
				//current是当前页数
				this.zhengcePageNum = current
				this.getZhengceList()
			},
			changeshixiang(current) {
				//current是当前页数
				this.xiangmuPageNum = current
				this.getPublicList()
			},
			// 拿到公告公示列表
			getPublicList() {
				let loadingNotice = Loading.service({
					target: this.$refs.notice,
				});
				let that = this;
				// type 1项目政策 2通知公告 3公开公示
				let params = {
					pageNum: this.xiangmuPageNum,
					pageSize: this.pageSize,
					type: 3,
					deptid: this.deptid,
				};
				this.$httpApi.get(`/dev-api/document/list`, params).then((res) => {
						console.log("公开公示列表:", res);
						if (res.code === 200) {
							loadingNotice.close()
							that.tableData = res.rows;
							that.lixiangNum = res.rows.length;
							that.gongkaiTotalSize = res.total;
						}

					})
					.catch((err) => {
						loadingNotice.close()
					});
			},
			// 拿到项目政策
			getZhengceList() {
				// type 1项目政策 2通知公告 3公开公示
				let loadingpolicySupport = Loading.service({
					target: this.$refs.policySupport,
				});
				let params = {
					pageNum: this.zhengcePageNum,
					pageSize: this.pageSize,
					type: 1,
					deptid: this.deptid,
				};
				console.log(params)
				this.$httpApi
					.get(`/dev-api/document/list`, params)
					.then((res) => {
						console.log("项目政策列表:", res);
						if (res.code === 200) {
							loadingpolicySupport.close();
							this.dataList = res.rows;
							this.totalSize = res.total;
						}
					})
					.catch((err) => {
						loadingpolicySupport.close();
						this.$message({
							message: "网络异常，请稍后重试",
							type: "error",
						});
					});
			},
			getTongzhiList() {
				let that = this;
				// type 1项目政策 2通知公告 3公开公示
				let params = {
					pageNum: this.tongzhiPageNum,
					pageSize: this.pageSize,
					type: 2,
					deptid: this.deptid,
				};
				this.$httpApi.get(`/dev-api/document/list`, params).then((res) => {
						console.log("通知公告:", res);
						if (res.code === 200) {
							that.tongzhiList = res.rows;
							that.tongzhiNum = res.rows.length;
							that.tongzhiTotalSize = res.total;
						}
			
					})
					.catch((err) => {
					});
			},
			getDeptInfo(){
				this.$httpApi
					.get(`/dev-api/system/dept/${this.deptid}`,{})
					.then((res) => {
						console.log("部门详情:", res);
						if (res.code === 200) {	
							this.dept = res.data
						}
					})
					.catch((err) => {
						this.$message({
							message: "网络异常，请稍后重试",
							type: "error",
						});
					});
			},
			getSPFCList() {
			  let loadingInstance = Loading.service({
			    target: this.$refs.findList,
			  });
			  let url = "";
			  let dlnm = +this.activeTab + 1 + ""; // 事项类型（1：审批类 2：产业资金类 3：诉求类）
			  let params = {
			    pageNum: 1,
			    pageSize: 100,
			    itemtype: dlnm,
			    deptCode:this.deptid
			  };
				console.log(dlnm)
			  if (["1", "2"].indexOf(dlnm) >= 0) {
			    url =
			      dlnm === "1"
			        ? "/dev-api/basicinfo/baseitem/xmsbList"
			        : "/dev-api/basicinfo/baseitem/zjl/xmsbList";

			  } else {
			    url = "/dev-api/basicinfo/baseitem/shuqiu/xmsbList";

			  }
			  console.log("请求参数>>>", params);
			  this.$httpApi
			    .get(url, params)
			    .then((res) => {
			      console.log("办事列表，审批类，扶持类>>>", res);
			      loadingInstance.close();
			      if (res.code == 200) {
			        let result = [];
			        result = res.rows;
			        this.shenbaoTotal = result.length;
			        this.targetArray = this.grouping(result, this.pagerSize);
			        this.showList = this.targetArray[0];
			      } else {
			      }
			    })
			    .catch((e) => {
			      loadingInstance.close();
			    });
			},
			// 处理数组函数
			grouping(array, n) {
			  let index = 0;
			  let newArray = [];
			  while (index < array.length) {
			    newArray.push(array.slice(index, (index += n)));
			  }
			  console.log("处理后的数组：", newArray);
			  return newArray;
			},
			/* 分页切换时，更换显示的数组 */
			handleCurrentChange(e) {
			  // console.log("页面转跳", e);
			  this.showList = this.targetArray[e - 1];
			},
			/*
			 * 监听DeclareCeil子组件点击获取详情事件
			 */
			clickCell(item) {
			  console.log("选择的是:", item);
			  // alert("转跳")
			  this.$store.commit("updateCurrentGuideId", item.id);
			  if (this.activeTab == 0 || this.activeTab == 1) {
			    this.$router.push({
			      path: "/guideShenpi",
			      query: {
			        name: item.name,
			        itemType: item.itemtype,
			      },
			    });
			  } else {
			    this.$router.push({
			      path: "/guideSuqiu",
			    });
			  }
			},
			// 切换组件的时候触发
			handleClick(tab, event) {
				this.activeTab =""+tab.index
				this.getSPFCList()
			},
		},
	};
</script>

<style lang="scss" scoped>
	.deptinfo-body {
		width: 70%;
		margin: 0 auto;
	}

	.img {
		width: 40%;
		margin-left: 30%;
	}

	.dept-detail {
		text-align: center;
		margin-left: 10px;

		span {
			font-size: 40px;
		}

		p {
			font-size: 20px;
		}
	}

	.dept-name {
		font-size: 50px;
	}

	.one-line {
		margin-left: 10px;
		border: 1px solid white;
		width: 1px;
		height: 50px;
	}
	.items-container {
	  width: 100%;
	  display: flex;
	  flex-direction: row;
	  justify-content: flex-start;
	  flex-wrap: wrap;
	  margin: 40px 10px 0;
	
	  .item {
		  
	    width: 30%;
	    box-sizing: border-box;
	    margin-right: 30px;
	    margin-bottom: 10px;
	  }
	}
	.notice-container {
	  min-height: 650px;
	
	  .banner-size {
	    background: url("../../assets/images/icon_banner_declare.png");
	  }
	
	  .banner-info {
	    // width: 100%;
	    height: 100%;
	    display: flex;
	    // justify-content: flex-start;
	    align-items: center;
	
	    .banner-fMax {
	      font-size: 40px;
	    }
	
	    .banner-other {
	      margin-left: 20px;
	      font-size: 20px;
	    }
	  }
	
	  .public-announcement {
	    // background: red;
	    // width: 100%;
	    // height: 100%;
	    display: flex;
	    flex-flow: column nowrap;
	    justify-content: flex-start;
	    align-items: center;
	  }
	}
	
</style>
