<template>
	<div class="section-container">
		<div class="section-header">
			<div class="section-title">{{title}}</div>
			<slot name="showMore"></slot>
		</div>
		<div class="order-select">
			<div>
				<slot name="left"></slot>
			</div>
			<div>
				<slot name="middle"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '',
			};
		},
		props: {
			title: {
				type: String,
				default: ''
			},
			totalSize: {
				type: Number,
				default: 0,
			},

		},
		mounted() {},
		methods: {
			/**
			 * 将搜索输入框中的数据发送给父组件
			 */
			searchByKeyword() {
				this.$emit('clickButton', this.keyword)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.section-container {
		margin-bottom: 30px;
	}

	.section-header {
		border-bottom: 4px solid #000;
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
	}

	.section-title {
		font-size: 24px;
		color: rgb(13, 28, 40);
		font-weight: 600;
		height: 46px;
		line-height: 46px;
	}


	.order-select {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 20px;

		.order-right {
			font-size: 14px;
			color: #828282;
			width: 260px;
			display: flex;
			justify-content: flex-end;
		}
	}

	.search-button {
		width: 40px;
		height: 40px;
		border-radius: 0px;
		border: 0px;
		background-color: rgb(69, 141, 252);
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
