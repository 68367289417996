<template>
  <div class="declareCell" @click="clickCell">
    <!-- 扶持方式 -->
    <div v-if="item.zcfs" class="item-fcfs">
      {{ getDictLabel("sq_policy_zjlx", item.zcfs) }}
    </div>
    <div class="item-inner">
      <div class="inner-left">
        <!-- 政策名称 -->
        <div class="item-title">
          <h2>{{ item.name || item.NAME || "" }}</h2>
        </div>
        <!-- 政策信息 -->
        <div class="item-info">
          <div class="info-item" v-for="(info, index) in infoList" :key="index">
            <label class="infoItem-label">{{ info.label }}：</label>
            <div class="infoItem-value">
              {{ info.formatter ? info.formatter(item) : item[info.valueKey] }}
            </div>
          </div>
        </div>
      </div>
      <div class="inner-right">
        <div>
          <span :style="{ color: cpu_limitTimeColor }">{{
            cpu_limitTime
          }}</span>
          <span class="marg-rl-2 right-line" style="margin: 0 10px">|</span>
          <span class="item-capital">{{ item.zgje || 500 }}万元</span>
        </div>
        <div>
          <div
            class="follow-img"
            :class="isFollow ? 'follow-img-on' : ''"
            @click.stop="clickIsFollow"
          ></div>
          <template v-if="item.isApply&&item.isApply=='1'">
            <el-button v-if="cpu_limitTime=='已过期'" disabled size="medium" @click.stop="apply"
              >一键申报
            </el-button>
            <el-button v-else class="item-go" size="medium" @click.stop="apply"
              >一键申报
            </el-button>
          </template>
          <el-button class="item-go" size="medium">办事指南 </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/dateHandler.js";
import variableStyle from "@/styles/variable.scss";

const NOW = new Date();

export default {
  name: "declareCell",
  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    bgColor: {
      type: String,
      default: function () {
        return "#ffffff";
      },
    },
    isShowText: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    collectItem: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      collectItemId: this.collectItem == null ? "" : this.collectItem.id,
      isFollow: this.collectItem != null,
      infoList: [
        {
          label: "受理部门",
          icon: "",
          formatter: (obj) => obj["dept_name"] || obj.deptName,
        },
        {
          label: "申报时间",
          icon: "",
          formatter: (obj) => {
            return `
						${formatDate(obj.start_time || obj.startTime, "date")}
						~
						${formatDate(obj.endtime, "date")}
						`;
          },
        },
        /*{
            label: '最高奖励', icon: '', formatter: (obj) => {
                return `${obj.zgje || 500}万元`
            }
        },*/
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictLabel: "dictionary/getDictLabel",
      storeguideId: "getCurrentGuideId",
    }),
    cpu_limitTime() {
      // 区分几种情况
      //     未开始/剩余天数/已过期
      let startTime = new Date(this.item.start_time||this.item.starttime);
      if (NOW < startTime) return "未开始";

      let endTime = new Date(this.item.endtime);
      if (endTime < NOW) return "已过期";

      let time = endTime.getTime() - NOW.getTime();
      return `剩${parseInt(time / (24 * 60 * 60 * 1000))}天`;
    },
    /**
     * @description: 剩余天数的颜色
     */
    cpu_limitTimeColor() {
      switch (this.cpu_limitTime) {
        case "未开始":
          return "";
        case "已过期":
          return variableStyle["color-red"];
        default:
          return variableStyle["color-yellow"];
      }
    },
  },
  methods: {
    // 一键申报
    apply() {
      this.$router.push({
        name: "policyDeclaration",
        query: {
          policyId: this.storeguideId,
        },
      });
    },
    clickCell() {
      // console.log("clickCell");
      this.$emit("clickCell", this.item);
    },
    clickIsFollow() {
      console.log("clickIsFollow", this.isFollow);
      let isFLW = this.item;
      isFLW.isShowText = !isFLW.isShowText;
      if (this.$store.getters.getUserInfo) {
        if (this.isFollow) {
          this.cancelCollect();
        } else {
          this.collect();
        }
      } else {
        this.$emit("clickIsFollow", this.item);
      }
    },
    //收藏
    collect() {
      let id = this.item.id || this.item.ID;
      console.log("收藏参数设置" + this.item);
      let identify = this.$store.getters.getUserInfo.shxydm;

      let name = this.item.name || this.item.NAME;
      let itemType = this.item.itemtype || this.item.ITEMTYPE;
      let collectedType = 4;
      if (itemType == "1") {
        collectedType = 4;
      } else if (itemType == "2") {
        collectedType = 5;
      } else if (itemType == "3") {
        collectedType = 6;
      }
      let param = {
        collectionName: name,
        collectionId: id,
        identify: identify,
        type: collectedType,
      };
      console.log("收藏参数：", param);
      this.$httpApi
        .post("/dev-api/collection/install", param)
        .then((res) => {
          console.log("添加收藏" + JSON.stringify(res));
          if (res.code == 200 && res.data) {
            this.collectItemId = res.id;
            this.isFollow = true;
          }
        })
        .catch((err) => {});
    },
    //取消收藏
    cancelCollect() {
      let collectItemId = this.collectItemId;
      console.log("取消收藏参数：", collectItemId);
      this.$httpApi
        .post("/dev-api/collection/delete/" + collectItemId, {})
        .then((res) => {
          console.log("取消收藏" + JSON.stringify(res));
          if (res.code == 200) {
            this.collectItemId = "";
            this.isFollow = false;
          }
        })
        .catch((err) => {});
    },
  },
  created() {
    this.guideId = this.item.id || this.storeguideId;
    this.$store.dispatch("dictionary/commonSetDic", {
      types: ["sq_policy_zjlx"],
    });
  },
};
</script>

<style lang="scss" scoped>
.declareCell {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 10px 20px 60px;
  border: 1px solid #eeeeee;
  background-color: #f9f9f9;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  overflow: hidden;

  .item-fcfs {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background-color: $col-yellow;
    // -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    // -moz-transform: translate(-50%, -50%) rotate(-45deg);
    // -o-transform: translate(-50%, -50%) rotate(-45deg);
    // -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(calc(-50% + 30px), calc(-50% + 30px)) rotate(-45deg);
  }

  .item-inner {
    display: -webkit-flex;
    display: flex;

    .inner-left {
      flex: 1;
    }

    .inner-right {
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 230px;
      min-height: 80px;
      padding-left: 10px;
      border-left: 2px solid #ffffff;
      text-align: center;
      // position: relative;

      .right-line {
        color: #cccccc;
      }

      .marg-rl-2 {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .item-title {
    margin-bottom: 10px;
    font-family: Microsoft YaHei;
    color: #333333;
  }

  .item-info {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 20px;
    color: #666666;

    .info-item {
      display: flex;

      .infoItem-value {
        flex: 1;
      }
    }
  }

  .item-capital {
    color: $col-red;
  }

  .follow-img,
  .item-go {
    vertical-align: middle;
  }

  .follow-img {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("../assets/images/favorites_off.png") center / 100% 100%
      no-repeat;
    position: absolute;
    left: 24px;
    top: 30px;
  }

  .follow-img-on {
    background: url("../assets/images/favorites_on.png") center / 100% 100%
      no-repeat;
  }
}

.declareCell:hover {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.item-go {
  margin-left: 10px;
  border-color: $col-theme;
  color: $col-theme;

  &:hover {
    color: #ffffff;
    background-color: $col-theme;
  }
}
::v-deep .el-button[disabled=disabled] {
  background-color: #fff !important;
}
</style>
