/**
 * @description: 格式化日期时间
 * @param {String|Number|Date} date 源日期时间
 * @param {String} formatter 格式
 *     支持使用下述关键字传入自定义的返回格式
 *         'date': yyyy-MM-dd
 *         'datetime': yyyy-MM-dd HH:mm:ss
 *         'time': HH:mm:ss
 * 
 *         yyyy 年份
 *         M 月份(不补0)  MM 月份(补0)
 *         d 天(不补0)  dd 天(补0)
 *         H 小时(不补0)  HH 小时(补0)
 *         m 分钟(不补0)  mm 分钟(补0)
 *         s 秒(不补0)  ss 秒(补0)
 * @return {String}
 */
export function formatDate(date, formatter) {
    let newDate = new Date(date)
    if (!newDate) return ''

    let yyyy = newDate.getFullYear()
    let [M, d] = [newDate.getMonth() + 1, newDate.getDate()]
    let [MM, dd] = [M < 10 ? '0' + M : M, d < 10 ? '0' + d : d]

    if (formatter === 'date') return `${yyyy}-${MM}-${dd}`

    let [H, m, s] = [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()]
    let [HH, mm, ss] = [H < 10 ? '0' + H : H, m < 10 ? '0' + m : m, s < 10 ? '0' + s : s]

    let res = ''
    switch (formatter) {
        case 'date':
            res = `${yyyy}-${MM}-${dd}`
            break
        default:
            res = (
                formatter
                    .replace(/yyyy/g, yyyy)
                    .replace(/MM/g, MM).replace(/M/g, M)
                    .replace(/dd/g, dd).replace(/d/g, d)
                    .replace(/HH/g, HH).replace(/H/g, H)
                    .replace(/mm/g, mm).replace(/m/g, m)
                    .replace(/ss/g, ss).replace(/s/g, s)
            )
            break
    }

    return res
}